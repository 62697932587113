import * as React from 'react';

import styled from 'styled-components';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withMarketingSection } from 'components/withMarketingSection';
import { BreadCrumb } from 'shared/CourseLanding/Detail/BreadCrumb';
import { colorsV2 } from 'style/colors-v2';
import { H1, Text } from '../Layout/SectionTypography';

export const TextContainer = styled.div`
  display: grid;
  gap: 8px;
  padding-bottom: ${DETAIL_HEADER_PADDING_BOTTOM / 3}px;
  .text {
    pointer-events: none;
    position: relative;
    z-index: 0;
  }
  .tooltip {
    color: ${colorsV2.primary100};
    cursor: pointer;
    padding: 40px;
    margin: -40px -40px -24px -40px;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 2;
  }
`;

export const BreadcrumbSectionHeaderUI = ({
  breadcrumbLinks,
  title,
  description,
  ...rest
}) => {
  return (
    <TextContainer {...rest}>
      <BreadCrumb links={breadcrumbLinks} />

      <H1>{title}</H1>
      <Text>{description}</Text>
    </TextContainer>
  );
};

const BreadcrumbSectionHeader = React.memo(
  withMarketingSection(BreadcrumbSectionHeaderUI, {
    style: { background: colorsV2.primary10, zIndex: 2 },
    selfish: true
  })
);

export const DETAIL_HEADER_PADDING_BOTTOM = 92;

export default BreadcrumbSectionHeader;
