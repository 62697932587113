import * as React from 'react';
import { useThrottle, useWindowScroll } from 'react-use';

/* eslint-disable react/prop-types */
import cl from 'classnames';
import { motion } from 'framer-motion';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { colorsV2 } from 'style/colors-v2';

import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';
import BubbleTitle from './BubbleTitle';
import IconHashTag from './icons/IconCodeHashTag';
import IconChecked from './icons/IconFilledCheckCircle';
import IconError from './icons/IconFilledErrorCircle';
import IconQuestion from './icons/IconFilledQuestionCircle';
import IconUserProfile from './icons/IconUserProfile';

const ComparisonTable = styled.div`
  grid-template-columns:
    minmax(auto, 4fr) minmax(min-content, 1fr) minmax(min-content, 1fr)
    minmax(min-content, 1fr);
  display: grid;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  .bold,
  .th {
    font-weight: 600;
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    //background-color: ${colorsV2.gray20};
    border-bottom: 1px solid ${colorsV2.gray20};
    padding: 8px;
    align-items: center;
  }
  .table-cell {
    padding: 8px;
    border-bottom: 1px solid ${colorsV2.gray20};
  }

  .highlight {
    background-color: #f4f8fc;
  }

  .empty-cell {
    height: 32px;
  }
`;

const Main = styled.div`
  .tag {
    padding: 0 8px;
    border-radius: 4px;
    border: 1px solid #242938;
    display: inline-flex;
  }
  .section-comparison-table {
    position: relative;
    .scholarship-cta {
      position: absolute;
      right: 0px;
    }
  }
  .${ComparisonTable} {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 32px;

    color: #242938;
    .highlight {
      background: #f4f8fc;
    }
    .bold {
      font-weight: 600;
    }
    .table-header {
      display: flex;
      border-bottom: 1px solid #d2d3d9;
      .th {
        padding: 8px;
        flex: 1;
        display: flex;
        align-items: center;
        .title-icon {
          margin-right: 8px;
          font-size: 20px;
          line-height: 0;
        }
      }
    }
    .table-row {
      display: flex;
      border-bottom: 1px solid #d2d3d9;
      &:last-child {
        border-bottom: initial;
      }
      .table-cell {
        padding: 8px;
        flex: 1;
      }
    }
  }

  @media ${deviceBreakpoints.mobile}, ${deviceBreakpoints.tablet} {
    .table {
      .primary {
        min-width: 222px;
      }
    }
    .tab-table-wrapper {
      position: sticky;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .tab-table {
        width: 100%;
        display: flex;
        border: 2px solid #e9e9ec;
        border-radius: 4px;
        margin-bottom: 20px;
        align-items: stretch;
        justify-content: center;
        display: flex;
        .tab-table-item {
          padding: 14px 16px;
          justify-content: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          background-color: #ffffff;
          &:first-child {
            border-radius: 4px 0px 0px 4px;
            border-right: 2px solid #e9e9ec;
          }
          &.flex {
            flex: 1;
          }

          &:last-child {
            border-radius: 0px 4px 4px 0px;
            border-left: 2px solid #e9e9ec;
          }
          &.active {
            background-color: ${colorsV2.primary100};
            font-weight: 600;
            color: ${colorsV2.white100};
          }
        }
      }
    }
  }
`;

const supportedIcon = {
  'hash-tag': IconHashTag,
  'user-profile': IconUserProfile
};

const CellStatusMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  .cell-status-icon {
    font-size: 24px;
  }
  .cell-status-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: ${colorsV2.black80};
  }
`;

const CellStatus = ({ status, text }) => {
  let Icon = null;
  switch (status) {
    case 'checked': {
      Icon = IconChecked;
      break;
    }
    case 'question': {
      Icon = IconQuestion;
      break;
    }
    case 'error': {
      Icon = IconError;
      break;
    }
    default:
  }

  return (
    <CellStatusMain>
      <div className="cell-status-icon">
        <Icon />
      </div>
      {text && <div className="cell-status-text">{text}</div>}
    </CellStatusMain>
  );
};

const SectionScholarshipComparisonTable = ({ data }) => {
  return (
    <Main className="section-scholarship-comparison-table">
      <BubbleTitle data={[{ text: 'So sánh', type: 'bubble' }]} />
      <div className="txt-description content-row font-secondary">
        DOL IELTS Đình Lực vs. Các trung tâm Tiếng Anh vs. Các giáo viên dạy tại
        nhà
      </div>
      <ComparisonTable className="section-comparison-table">
        {data.map(table => {
          const { title, rows, icon } = table;
          const IconTitle = supportedIcon[icon];
          return (
            <>
              <div className="th bold primary">
                <div className="title-icon">
                  <IconTitle />
                </div>
                <div className="title-text">{title}</div>
              </div>
              <div className={cl('th bold highlight')}>DOL English</div>
              <div className={cl('th')}>Trung tâm lớn</div>
              <div className={cl('th')}>GV tại nhà</div>
              {rows.map(column => {
                const { text, dol, anotherEnglishCenter, homeTeacher } = column;
                return (
                  <>
                    <div className="table-cell primary">
                      <div className="tag">{text}</div>
                    </div>
                    <div className={cl('table-cell highlight')}>
                      <CellStatus {...dol} />
                    </div>
                    <div className={cl('table-cell')}>
                      <CellStatus {...anotherEnglishCenter} />
                    </div>
                    <div className={cl('table-cell')}>
                      <CellStatus {...homeTeacher} />
                    </div>
                  </>
                );
              })}

              <div className="empty-cell" />
              <div className="empty-cell" />
              <div className="empty-cell" />
              <div className="empty-cell" />
            </>
          );
        })}
      </ComparisonTable>
    </Main>
  );
};

const comparisonTableQuery = graphql`
  query ComparisonTableQuery {
    gcms {
      comparisonTable: jsonContanier(
        where: { name: "LandingSchedule_ComparisonTable" }
      ) {
        id
        content
      }
    }
  }
`;

const SectionScholarshipComparisonTableMain = () => {
  return (
    <StaticQuery
      query={comparisonTableQuery}
      render={data => {
        const { comparisonTable } = data.gcms.comparisonTable.content;

        return <SectionScholarshipComparisonTable data={comparisonTable} />;
      }}
    />
  );
};

export default SectionScholarshipComparisonTableMain;
